// Normalize Styles
@import (inline) 'node_modules/normalize.css/normalize.css';

// Box sizing partial styles:
// Apply a natural box layout model to all elements
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

// Media Queries
@xs: ~'screen and (max-width: 380px)';
@s: ~'screen and (max-width: 480px)';
@mobile: ~'screen and (max-width: 600px)';
@tablet: ~'screen and (min-width: 601px) and (max-width: 1000px)';
@desktop: ~'screen and (min-width: 1001px)';

.content-container {
  padding: 20px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

body {
  background-image: url('../images/bg.jpg');
  background-color: #FEEED8;
  color: #413E3A;
  font-family: "Georgia";
}

a {
  color: #FE6651;
}

h1 {
  font-family: "Helvetica";
  font-weight: 700;
  font-size: 65px;
  line-height: 80px;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 0;

  @media @mobile {
    font-size: 52px;
    line-height: 68px;
  }

  @media @s {
    font-size: 42px;
    line-height: 50px;
  }

  @media @xs {
    font-size: 32px;
    line-height: 40px;
  }
}

h1 span {
  color: #FE6651;
}

h2 {
  font-family: "Helvetica";
  font-weight: 700;
  font-size: 45px;
  line-height: 60px;
}

p {
  font-size: 24px;
  line-height: 36px;

  @media @mobile {
    font-size: 22px;
    line-height: 32px
  }

  @media @s {
    font-size: 20px;
    line-height: 30px
  }

  @media @xs {
    font-size: 18px;
    line-height: 28px
  }
}

ul {
  font-size: 20px;
  line-height: 36px;
  list-style-type: none;
  margin: 0;
  padding: 0;

  @media @mobile {
    font-size: 18px;
    line-height: 32px
  }

  @media @s {
    font-size: 16px;
    line-height: 30px
  }

  li:before {
    font-family: "FontAwesome";
    margin-right: 5px;
  }

  .github:before {
    content: '\f09b';
  }

  .linkedin:before {
    content: '\f08c';
  }

  .twitter:before {
    content: '\f099';
  }

  .email:before {
    content: '\f0e0';
  }
}

.profile-picture {
  display: block;
  height: 300px;
  border-radius: 100%;
  margin: 0 auto;
  padding: 1px;
  border: 2px solid #E5D7C3;

  @media @mobile {
    height: 200px;
  }
}
